<template>
    Messages
    <ul>
        <li v-for="message in messages" v-bind:key="message">
            {{message}}
        </li>
    </ul>
</template>

<script>
import Pusher from "pusher-js";
Pusher.logToConsole = true;
export default {
    name: "Pusher test",
    data() {
        return {
            messages: [],
        }
    },

    mounted() {
        let pusher = new Pusher('c7d8c71932aef858bcc6', {
            cluster: 'eu'
        });

        let channel = pusher.subscribe('payments-channel');
        channel.bind('payment-accepted', function (data) {
            this.messages.push(data);
        }, this);
    }
}
</script>
